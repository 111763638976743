import _collection from "../internals/collection";
import _collectionStrong from "../internals/collection-strong";
var collection = _collection;
var collectionStrong = _collectionStrong;

// `Set` constructor
// https://tc39.es/ecma262/#sec-set-objects
collection("Set", function (init) {
  return function Set() {
    return init(this, arguments.length ? arguments[0] : undefined);
  };
}, collectionStrong);
export default {};